.jumpPage {
    width: 100%;

    .container {
        margin: 0 auto;
        padding: 20px;
        background-color: #DBEEF3;
    }
    .container2 {        
        padding-left: 3%;
    }    
    .header-text {
        width: 20%;
        display: inline-block;
        font-family: sans-serif;
        font-size: 20px;
        text-align: center;
        font-weight: 600;
    }
    .sub-header-row {
        font-size: 17px;
        font-family: sans-serif;
        display: inline-flex;
    }
    .section {
        width: 20%;			
        display: inline-block;
    }
    .section1 {
        width: 30%;			
        display: inline-block;
    }
    .section1buttton {
        width: 15%;			
        display: inline-block;
    }
    .section2 {
        width: 25%;			
        display: inline-block;
    }				
    .btn {width: 100px;}
    .right-margin{margin-right: 10px}
    .width{width: 30%;}
    .align-width{width: 50%;}
}