@import './fonts/OpenSans';

.homepage {
    
    /* crosshair */
    .modcharts-crosshair-vert { stroke: #999; stroke-width: 2; stroke-dasharray:none; }
    .modcharts-crosshair-circle { display:block; fill: #40A4D9; stroke: #FFF; width:3.5px; }
    
    width: 100%;

    .disclaimer{
        font-size: 14px;
        padding-top: 2px;
    }
    
    .container{
        margin: 0 auto;
    }
    .symbol {
        font-size: 16px;
        margin: 5px 0px;
    }
    div {
        display: block;
    }
    .text-color {
        color: #4B4B4B;
    }
    .heading-color {
        color: #000000;
    }
    .days-color{
        color:#717171;
    }
    .bid-ask {
        width: 100%;
    }
    .bid-ask-1{
        display: block;
        width: 100%;
    }
    .bid-ask-row {
        padding: 10px 0px;
        border-bottom: 2px solid #e4e4e4;
        color: black;
        margin-right: 15px;
    }
    .bid-ask-2{
        display: block;
        width: 100%;
    }
    .bid-ask-col1 {
        display: inline-block;
        width: 50%;
        font-size: 14px;
    }
    .bid-ask-col2 {
        display: inline-block;
        width: 50%;
        font-size: 15px;
        font-weight: bold;
        text-align: right;
    }
    .chart-div {
        display: none;
    }
    .chart-div-2{
        margin-top: 3px;
        display: block;
        width: 100%;
        font-weight: bold;
        font-size: 14px;
        line-height: 32px;
    }
    .premium-discount {
        display: block;
        width: 100%;
        margin-top: 20px;
    }
    .premium-discount-table {
        width: 97%;
        border: 2px solid #e4e4e4;
        font-size: 14px;
        th, td {
            text-align: center;
        }
        > thead tr:first-child th:first-child {
            vertical-align: top;
            text-align: left;
        }
        > thead tr:first-child {
            th:nth-child(1) {
                width: 160px;
            }
            th:nth-child(2) {
                width: 29%;
            }
            th:nth-child(3) {
                width: 29%;
            }
        }
        > tbody tr td:first-child {
            text-align: left;
            padding-top: 5px;
        }
    }
    .premium-discount1, .premium-discount2, .premium-discount-row2, .premium-discount-row3{
        display: inline-block;
        width:50%;
    }
    .premium-discount-row-child-1 {
        font-size: 12px;
        padding: 10px 0px;
        font-weight: bold;
    }
    .premium-discount-row1 {
        font-size: 14px;
        font-weight: bold;
    }
    .premium-discount-row-child-2 {
        font-size: 21px;
        font-weight: bold;
    }
    .days-traded {
        width: 85px
    }
    @media all and (min-width: 1024px) {
        .container{
            padding: 20px;
        }
        .bid-ask {
            display: inline-block;
            width: 36%;
        }
        .chart-div-2 {
            margin-top: 3px;
            display: inline-block;
            width: 64%;
            vertical-align: top;
            font-weight: bold;
            font-size: 14px;
            line-height: 32px;
        }
        .chart-div {
            display: none;
        }
        .premium-discount-table {
            width: 96%;
        }
    }
    @media all and (min-width: 768px) and (max-width: 1023.98px) {
        .container{
            padding: 20px;
        }
        .bid-ask-1{
            display: inline-block;
            width: 50%;
            vertical-align: top;
        }
        .bid-ask-2{
            display: inline-block;
            width: 50%;
            vertical-align: top;
        }
        .chart-div {
            margin-top: 40px;
            display: block;
            width: 100%;
            font-weight: bold;
            font-size: 14px;
            line-height: 32px;
        }
        .chart-div-2 {
            display: none;
        }
        .premium-discount-table {
            width: 98%;
        }
    }
    @media all and (min-width: 200px) and (max-width: 767.98px) {
        .container{
            padding: 20px;
        }
        .premium-discount1, .premium-discount2{
            display: block;
            width:100%;
        }
        .premium-discount-row1{
            margin-top: 10px;
        }
        .chart-div {
            margin-top: 40px;
            display: block;
            width: 100%;
            font-weight: bold;
            font-size: 14px;
            line-height: 32px;
        }
        .chart-div-2 {
            display: none;
        }
        .premium-discount-table {
            width: 97%;
        }
    }

/* Mobile */
@media all and (min-width: 200px) and (max-width: 767.98px)  { #premDiscountChart { width:100%; height:159px} }

/* Ipad */
@media all and (min-width: 768px) and (max-width: 1023.98px)  { #premDiscountChart { width:100%; height:314px} }

/* Desktop */
@media all and (min-width: 1024px) { #desktopPremDiscountChart { width:100%; height:314px} }}