.premium-discount-desktop-heading {
    padding-top: 0px;
    margin-top: -6px;
}

.date-hover-desktop-class {
    font-weight: 100;
    padding-right: 4px;
    line-height: 16px;
}

.premDiscount-hover-desktop-class {
    font-weight: 100;
    padding-left: 15px;
    padding-right: 4px;
    line-height: 16px;
}

.date-discount-value-desktop-class {
    font-size: 16px;
    line-height: 16px;
}