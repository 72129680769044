.modcharts-rootmouse {
	position: absolute;
	opacity:0;
	filter: alpha(opacity=0);
	background-color:rgba(0,0,0,0); /* ie */
}

.modcharts-rootmessages {
	font-family:Arial;
	position: absolute;
	color: #666;
	font-size:15px;
	z-index:1;
	padding:0;
	width:100%;
	height:100%;
}

.modcharts-rootmessages .modcharts-chartnotavailable {
	padding:50px;
	margin:auto;
	width:250px;
	height:70px;
}

.modcharts-noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.modcharts-loading {
	width: 35px;
	height: 35px;
	position:absolute;
	z-index: 3;
}

.modcharts-rootoverlay line { z-index:2; }

/* chart */
.modcharts-root { position: relative; }
.modcharts-panel { position: absolute; }

/* panel legend */
.modcharts-legend {
	position: absolute;
	z-index:1;
	visibility:hidden;
	font-size:11px;
	font-family: Arial, Helvetica;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-top:8px;
}
.modcharts-root-loading { pointer-events: none; }
.modcharts-root-loading .modcharts-legend { opacity: 0.5; }
.modcharts-grid { color: #DDD; }
.modcharts-grid-alt { color: #777; }
.modcharts-grid-ticks { color: #777; }
.modcharts-grid-ticks-alt { color: #777; }
.modcharts-grid-ticks-horiz { color: transparent; }
.modcharts-grid-horiz-normalize { color: #777; }
.modcharts-grid-vert { width: 1px; }
.modcharts-grid-vert-normalize { color: #777; }
.modcharts-grid-vert-penpxon { width: 1px;}
.modcharts-grid-vert-penpxoff { width: 2px;}
.modcharts-grid-horiz-penpxon { width: 1px;}
.modcharts-grid-horiz-penpxoff { width: 1px;}
.modcharts-grid-vert-alt-penpxon { width: 1px;}
.modcharts-grid-vert-alt-penpxoff { width: 1px;}
.modcharts-axis { color: #666; font-size: 11px; font-family: Arial, Helvetica, sans-serif; }
.modcharts-xaxis { text-align: left; padding-left: 2px; padding-top: 5px; line-height: 15px; }
.modcharts-xaxis-tick { height: 6px; }
.modcharts-xaxis-tick-alt { height: 6px; }
.modcharts-yaxis { padding-top: 0px; padding-bottom: 0px; }
.modcharts-cursor-default { cursor: default; }
.modcharts-cursor-pointer {	cursor: pointer; }
.modcharts-cursor-ns-resize { cursor: ns-resize; }

	
/*

comparison line colors - define these in your project's local css file

.modcharts-marker-linecolor-0 { color: #df9224; }
.modcharts-marker-linecolor-1 { color: #1a70c7; }
.modcharts-marker-linecolor-2 { color: #c71ac1; }
.modcharts-marker-linecolor-3 { color: #d4c521; }
...

*/

/*

flags - define these in your project's local css file

.modcharts-flag-lastclose { color:orange; background-color:yellow; height:50px;}
.modcharts-flag-crosshair { color:purple; background-color:hotpink; height:50px;}
 */

/* labels */
.modcharts-label-closure { color: rgba(255,255,255,0.12); }

/* indicator params */
.modcharts-indicator-adl { color: pink; width: 1.5px; }
.modcharts-indicator-advancedecline { color: #49CF6B; width: 1.5px; }
.modcharts-indicator-avgtruerange { color: lightblue; width: 1.5px; }
.modcharts-indicator-awesomeoscillator-pos { color: forestgreen; }
.modcharts-indicator-awesomeoscillator-neg { color: firebrick; }
.modcharts-indicator-bollinger { color: rgba(136, 136, 136, 0.8); width: 1.5px; }
.modcharts-indicator-bollinger-fill { color: rgba(194, 235, 254, 0.09); }
.modcharts-indicator-bollinger-sma { color: green; }
.modcharts-indicator-bollingerbandwidth { color: #FFCB6A; width: 1.5px; }
.modcharts-indicator-candlestick { color: #777; }
.modcharts-indicator-candlestick-pos { color: forestgreen; }
.modcharts-indicator-candlestick-neg { color: firebrick; }
.modcharts-indicator-chaikins { color: #32868F; width: 1.5px; }

.modcharts-indicator-commoditychannelindex { color: yellow; width: 1px; }
.modcharts-indicator-commoditychannelindex-overbought { color: steelblue; background-color: rgba(0, 0, 0, 0); width: 4px; }
.modcharts-indicator-commoditychannelindex-oversold { color: steelblue; background-color: rgba(0, 0, 0, 0); width: 4px; }

.modcharts-indicator-crs { color: #49CF6B; width: 1.5px; }
.modcharts-indicator-dividendyield { color: lightblue; width: 1.5px; }
.modcharts-indicator-dmi-adx { color: #0074E8; width: 1.5px; }
.modcharts-indicator-dmi-adxr { color: orange; width: 1.5px; }
.modcharts-indicator-dmi-neg { color: lightblue; }
.modcharts-indicator-dmi-pos { color: orange; }
.modcharts-indicator-ema { color:#777; width: 1.5px; }
.modcharts-indicator-linearregression { color: orange; width: 1.5px; }
.modcharts-indicator-macd { color: pink; width: 1.5px; }
.modcharts-indicator-macd-ema { color: #888; }
.modcharts-indicator-macd-pos { color: forestgreen; }
.modcharts-indicator-macd-neg { color: firebrick; }
.modcharts-indicator-mae { color: #999; width: 1.5px; }
.modcharts-indicator-mae-fill { color: rgba(194, 235, 254, 0.15); }
.modcharts-indicator-massindex { color: #672D8A; width: 1.5px; }
.modcharts-indicator-massindex-setup { color: #EA7125; }
.modcharts-indicator-massindex-trigger { color: #00A0DF; }
.modcharts-indicator-momentum { color: #9A51C6; width: 1.5px; background-color: rgba(100, 136, 136, 0.2);}
.modcharts-indicator-moneyflow { color: darkred; width: 1.5px; }
.modcharts-indicator-moneyflowindex { color: purple; width: 1.5px; }
.modcharts-indicator-moneyflowindex-overbought { color:rgb(84,84,84); background-color: rgba(255, 0, 0, 0.1); }
.modcharts-indicator-moneyflowindex-oversold { color:rgb(84, 84, 84); background-color: rgba(0, 255, 0, 0.1); }
.modcharts-indicator-onbalancevolume { color: darkorange; width: 1.5px; }
.modcharts-indicator-perange { color: #275630; width: 1.5px; }
.modcharts-indicator-perange-low { color: green; }
.modcharts-indicator-peratio { color:orange; width: 1.5px; }

.modcharts-indicator-percentb { color: darkorange; width: 1px; }
.modcharts-indicator-percentb-overbought { color: forestgreen; background-color: rgba(0, 0, 0, 0); width: 3px; }
.modcharts-indicator-percentb-median { color: steelblue; width: 3px; }
.modcharts-indicator-percentb-oversold { color: forestgreen; background-color: rgba(0, 0, 0, 0); width: 3px; }

.modcharts-indicator-previousclose { color: darkred; width: 1.5px; }
.modcharts-indicator-previousclose-label { color: #333; font-weight: normal; font-family: Arial, Helvetica, sans-serif; font-size:11px; }

.modcharts-indicator-price { color: steelblue; width: 1.5px; }
.modcharts-indicator-price-lineup { color: green; }
.modcharts-indicator-price-linedown { color: darkred; }
.modcharts-indicator-price-fill { color: none; }
.modcharts-indicator-price-fillstart { color: none; }
.modcharts-indicator-price-fillstop { color: none; }
.modcharts-indicator-price-fillpos { color: forestgreen; }
.modcharts-indicator-price-fillneg { color: firebrick; }
.modcharts-indicator-price-dot { /* color: white;*/ }
.modcharts-indicator-price-dot-fill { color: steelblue; width:5px; }
.modcharts-indicator-price-sessionbreak { /* color: #999; */ width: 1.5px; }
.modcharts-indicator-price-sessionbreak-penpxon { width: 2px; }
.modcharts-indicator-price-sessionbreak-penpxoff { width: 2px; }
.modcharts-indicator-price-fill-sessionbreak { /* color: #999; */ }

.modcharts-indicator-pricechannel { color: #888; width: 1.5px; }
.modcharts-indicator-pricechannel-down { color: #666; }
.modcharts-indicator-pricechannel-fill { color: rgba(194, 235, 254, 0.15); }
.modcharts-indicator-proc { color: green; width: 1.5px; }
.modcharts-indicator-proc-zero { color: #000; }
.modcharts-indicator-psar { color: #EA4D00; width: 2px; }
.modcharts-indicator-revenue { color: #AB491F; width: 1px; }
.modcharts-indicator-rollingeps { color: #EEA00F; width: 1px; }
.modcharts-indicator-rollingdividend { color: #26A42D; width: 1px; }
.modcharts-indicator-rsi { color:orange; width: 1.5px; }
.modcharts-indicator-rsi-overbought { color:rgb(84,84,84); background-color: rgba(255, 0, 0, 0.1); }
.modcharts-indicator-rsi-oversold { color:rgb(84,84,84); background-color: rgba(0, 255, 0, 0.1); }
.modcharts-indicator-sectorindustry { color:#EA4D00; width: 1.5px; }
.modcharts-indicator-sma { color:#72D6FC; width: 1.5px; }
.modcharts-indicator-stochastics { color: #666; width: 1.5px; }
.modcharts-indicator-stochastics-overbought { color:rgb(84,84,84); background-color:rgba(255,0,0,0.1); }
.modcharts-indicator-stochastics-oversold { color:rgb(84,84,84); background-color:rgba(0,255,0,0.1); }
.modcharts-indicator-stochastics-d { color: #600; }
.modcharts-indicator-tsf { color: red; width: 1.5px; }
.modcharts-indicator-ultimateoscillator { color: #555; width: 1.5px; }
.modcharts-indicator-ultimateoscillator-overbought { color:rgb(84,84,84); background-color:rgba(255,0,0,0.1); }
.modcharts-indicator-ultimateoscillator-oversold { color:rgb(84,84,84); background-color:rgba(0,255,0,0.1); }
.modcharts-indicator-volume { color: steelblue; }
.modcharts-indicator-volume-pos { color: forestgreen; }
.modcharts-indicator-volume-neg { color: firebrick; }
.modcharts-indicator-volumebyprice-up { color: rgba(34, 139, 34, 0.5); }
.modcharts-indicator-volumebyprice-down { color: rgba(178, 34, 34, 0.5); }
.modcharts-indicator-vroc { color: #BF6900; width: 1.5px; }
.modcharts-indicator-vroc-zero { color: #333; }
.modcharts-indicator-williamspctr { color: #B8814E; width: 1.5px; }
.modcharts-indicator-williamspctr-overbought { color:rgb(84,84,84); background-color:rgba(255,0,0,0.1); }
.modcharts-indicator-williamspctr-oversold { color:rgb(84,84,84); background-color:rgba(0,255,0,0.1); }
.modcharts-indicator-wma { color: #777; width: 1.5px; }
.modcharts-indicator-updownratio { color: #72D6FB; width: 1.5px; }
/*.modcharts-indicator-updown { color: brown; }*/

/* tools */
.modcharts-tool-line { color: #80B9FF; width: 1.5px; }
.modcharts-tool-line-handle { color: #000; width: 10px; background-color: white; }
.modcharts-tool-line-fibonacci { color: white; width: 1px;}
.modcharts-tool-line-ray { color: purple; width: 2px;}
.modcharts-tool-line-extended { color: orange; width: 2px;}
.modcharts-tool-line-ellipse { color: orange; width: 2px; background-color:none;}
.modcharts-tool-line-rect { color: darkred; width: 2px; background-color:none;}
.modcharts-tool-extended-horizontal-support { color: limegreen;	width: 2px; }
.modcharts-tool-extended-horizontal-resistance { color: red; width: 2px; }
.modcharts-tool-extended-horizontal-default { color: pink; width: 2px; }

/* crosshair and circle */
.modcharts-crosshair { position: absolute; color: #ccc; height: 20px; }
.modcharts-crosshair-horiz,
.modcharts-crosshair-vert { stroke: #777; stroke-width: 1; stroke-dasharray: 2,1; shape-rendering:crispEdges; }
.modcharts-crosshair-circle { display:none; fill: #fff; stroke: #333; width:3.5px; stroke-width: 1; }

.modcharts-toolconfig {
	position:absolute;
	z-index:99;
	font-family:Arial;
	font-size: 12px;
	display: none;
	cursor: default;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.modcharts-toolconfig span {
	line-height: 28px;
}

.modcharts-toolconfig-toggle {
	height: 25px;
	font-size: 11px;
	cursor: pointer;
	z-index: 10;
	font-size: 11px;
	padding:0 2px;
	margin:0;
	line-height: 0;
	height: 15px;
	width: 15px;
}

	.modcharts-toolconfig input {
		cursor: pointer;
	}

	.modcharts-toolconfig input.symbol {
		border: 1px solid #aaa;
		font-size: 16px;
		height: 21px;
		text-transform:uppercase;
		padding:0;
		width:60px;
	}

.modcharts-toolconfig .modcharts-toolconfig-content {
	width:370px;
	height:135px;
	background: #fff;
	border: 1px solid #aaa;
	border-radius:5px;
	display: none;
	padding: 10px;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

.modcharts-toolconfig .modcharts-toolconfig-content input[type="text"] {
	float:left;
	margin-bottom:5px;
}

.modcharts-toolconfig .modcharts-toolconfig-content input[type="button"] {
	margin:15px 10px 0 0;
}

.modcharts-toolconfig .modcharts-toolconfig-content-key {
	float:left;
	width:75px;
}

.modcharts-toolconfig select {
	width: 130px;
}

.modcharts-contains:before,
.modcharts-contains:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.modcharts-contains:after {
    clear: both;
}
